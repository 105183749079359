<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" md="3" lg="2">
        <v-select
          v-model="selectedXaxis"
          v-on:change="changeXaxis"
          :items="items"
          :menu-props="{ top: true, offsetY: true }"
          label="X axis"
        ></v-select>
        <v-select
          v-model="selectedYaxis"
          v-on:change="changeYaxis"
          :items="items"
          :menu-props="{ top: true, offsetY: true }"
          label="Y axis"
        ></v-select>
      </v-col>
      <v-col cols="12" md="9" lg="10">
        <div id="ScatterPlot"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: {
    datum: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      predicted: {
        data: this.datum.predicted,
        min: 0,
        max: 0,
        xScale: 0,
        yScale: 0
      },
      logp: {
        data: this.unpackOthers(this.datum, "logp"),
        min: 0,
        max: 0,
        xScale: 0,
        yScale: 0
      },
      hba: {
        data: this.unpackOthers(this.datum, "hba"),
        min: 0,
        max: 0,
        xScale: 0,
        yScale: 0
      },
      hbd: {
        data: this.unpackOthers(this.datum, "hbd"),
        min: 0,
        max: 0,
        xScale: 0,
        yScale: 0
      },
      mw: {
        data: this.unpackOthers(this.datum, "mw"),
        min: 0,
        max: 0,
        xScale: 0,
        yScale: 0
      },
      rotable_bond: {
        data: this.unpackOthers(this.datum, "rotatable_bond"),
        min: 0,
        max: 0,
        xScale: 0,
        yScale: 0
      },
      //   items: ["LogP", "HBA", "HBD", "Molecular weight", "Rotatable bond"],
      items: [
        {
          text: "Predicted pIC50",
          value: "predicted"
        },
        {
          text: "LogP",
          value: "logp"
        },
        {
          text: "HBA",
          value: "hba"
        },
        {
          text: "HBD",
          value: "hbd"
        },
        {
          text: "Molecular weight",
          value: "mw"
        },
        {
          text: "Rotatable bond",
          value: "rotable_bond"
        }
      ],
      selectedXaxis: {
        text: "Molecular weight",
        value: "mw"
      },
      selectedYaxis: {
        text: "Predicted pIC50",
        value: "predicted"
      },
      data: [
        {
          x: [],
          y: [],
          mode: "markers", // "lines", "markers", "lines+markers", "lines+markers+text", "none"
          type: "scatter",
          name: "",
          marker: {
            size: 12,
            color: "rgb(108, 97, 176)"
            // gradient: {
            //   type: "horizontal", // "radial", "horizontal", "vertical", "none"
            //   color: ["#00A09D"]
            // }
          },
          //   text: "test",
          //   texttemplate: "y: %{y}", must set mode to text
          //   hovertext: "eiei",
          hoverinfo: "x+y", // "x", "y", "all", "text", "name"
          hovertemplate: ""
        }
      ],
      layout: {
        xaxis: {
          zeroline: false,
          title: "X axis label",
          range: [0.75, 5.25]
        },
        yaxis: {
          zeroline: false,
          title: "Y axis label",
          range: [0, 20]
        },
        title: "Scatter Plot",
        autosize: true

        // annotations: [
        //   {
        //     xref: "paper",
        //     yref: "paper",
        //     x: 0,
        //     xanchor: "center",
        //     y: 1,
        //     yanchor: "bottom",
        //     text: "Y axis label",
        //     showarrow: false
        //   },
        //   {
        //     xref: "paper",
        //     yref: "paper",
        //     x: 1,
        //     xanchor: "left",
        //     y: 0,
        //     yanchor: "top",
        //     text: "X axis label",
        //     showarrow: false
        //   }
        // ]
      }
    };
  },
  mounted() {
    // Register an event listener when the Vue component is ready
    window.addEventListener("resize", this.onResize);

    // Initial data on scatter plot
    this.data[0].x = this[this.selectedXaxis.value].data;
    this.data[0].y = this[this.selectedYaxis.value].data;

    // Find minimum and maximum of each key for scatter plot
    this.findMinandMax(this.datum, this.items);

    // Initial range of scatter plot's axes
    // Initial range of x axis
    this.layout.xaxis.range = [
      this[this.selectedXaxis.value].min -
        this[this.selectedXaxis.value].xScale,
      this[this.selectedXaxis.value].max + this[this.selectedXaxis.value].xScale
    ];
    // Initial range of y axis
    this.layout.yaxis.range = [
      this[this.selectedYaxis.value].min -
        this[this.selectedYaxis.value].yScale,
      this[this.selectedYaxis.value].max + this[this.selectedYaxis.value].yScale
    ];

    // Set the title of scatter plot
    this.layout.title = `Scatter Plot of ${this.selectedYaxis.text} vs ${this.selectedXaxis.text}`;

    // Set labels on hover
    this.data[0].hovertemplate = `${this.selectedYaxis.text}: %{y}<br>${this.selectedXaxis.text}: %{x}`;

    // Set titles of axes
    this.layout.xaxis.title = this.selectedXaxis.text;
    this.layout.yaxis.title = this.selectedYaxis.text;
    // this.layout.annotations[0].text = this.selectedYaxis.text;
    // this.layout.annotations[1].text = this.selectedXaxis.text;

    // Initial scatter plot
    Plotly.newPlot("ScatterPlot", this.data, this.layout);
  },
  methods: {
    onResize() {
      Plotly.newPlot("ScatterPlot", this.data, this.layout);
    },

    unpackOthers(rows, key) {
      return rows.map(function(row) {
        return row["others"][key];
      });
    },

    findMinandMax(rows, items) {
      items.forEach(key => {
        let temp_data = rows.map(row => {
          return row["others"][key.value];
        });
        this[key.value].min = Math.min(...temp_data);
        this[key.value].max = Math.max(...temp_data);

        this[key.value].xScale =
          (this[key.value].max - this[key.value].min) / 10;
        this[key.value].yScale =
          (this[key.value].max - this[key.value].min) / 10;

        // console.log("-----------------");
        // console.log(
        //   key.value,
        //   "min: ",
        //   this[key.value].min,
        //   "max: ",
        //   this[key.value].max
        // );
        // console.log(
        //   key.value,
        //   "xScale: ",
        //   this[key.value].xScale,
        //   "yScale: ",
        //   this[key.value].yScale
        // );
      });
    },

    checkTitle(title) {
      if (title == "predicted") return "Predicted pIC50";
      if (title == "logp") return "LogP";
      if (title == "hba") return "HBA";
      if (title == "hbd") return "HBD";
      if (title == "mw") return "Molecular weight";
      if (title == "rotable_bond") return "Rotatable bond";
    },

    changeXaxis() {
      this.data[0].x = this[this.selectedXaxis].data;

      if (this.selectedYaxis[0] == undefined) {
        this.layout.title = `Scatter Plot of ${this.checkTitle(
          this.selectedYaxis.value
        )} vs ${this.checkTitle(this.selectedXaxis)}`;

        // Set labels on hover
        this.data[0].hovertemplate = `${this.checkTitle(
          this.selectedYaxis.value
        )}: %{y}<br>${this.checkTitle(this.selectedXaxis)}: %{x}`;
      } else {
        this.layout.title = `Scatter Plot of ${this.checkTitle(
          this.selectedYaxis
        )} vs ${this.checkTitle(this.selectedXaxis)}`;

        // Set labels on hover
        this.data[0].hovertemplate = `${this.checkTitle(
          this.selectedYaxis
        )}: %{y}<br>${this.checkTitle(this.selectedXaxis)}: %{x}`;
      }

      //   Rerange of x axis
      this.layout.xaxis.range = [
        this[this.selectedXaxis].min - this[this.selectedXaxis].yScale,
        this[this.selectedXaxis].max + this[this.selectedXaxis].yScale
      ];

      // Re-set title of x axis
      this.layout.xaxis.title = this.checkTitle(this.selectedXaxis);

      //   console.log(
      //     this.selectedXaxis,
      //     "min: ",
      //     this[this.selectedXaxis].min,
      //     "max: ",
      //     this[this.selectedXaxis].max,
      //     "yScale:",
      //     this[this.selectedXaxis].yScale
      //   );

      Plotly.newPlot("ScatterPlot", this.data, this.layout);
    },

    changeYaxis() {
      this.data[0].y = this[this.selectedYaxis].data;

      if (this.selectedXaxis[0] == undefined) {
        this.layout.title = `Scatter Plot of ${this.checkTitle(
          this.selectedYaxis
        )} vs ${this.checkTitle(this.selectedXaxis.value)}`;

        // Set labels on hover
        this.data[0].hovertemplate = `${this.checkTitle(
          this.selectedYaxis
        )}: %{y}<br>${this.checkTitle(this.selectedXaxis.value)}: %{x}`;
      } else {
        this.layout.title = `Scatter Plot of ${this.checkTitle(
          this.selectedYaxis
        )} vs ${this.checkTitle(this.selectedXaxis)}`;

        // Set labels on hover
        this.data[0].hovertemplate = `${this.checkTitle(
          this.selectedYaxis
        )}: %{y}<br>${this.checkTitle(this.selectedXaxis)}: %{x}`;
      }

      //   Rerange of y axis
      this.layout.yaxis.range = [
        this[this.selectedYaxis].min - this[this.selectedYaxis].yScale,
        this[this.selectedYaxis].max + this[this.selectedYaxis].yScale
      ];

      // Re-set title of y axis
      this.layout.yaxis.title = this.checkTitle(this.selectedYaxis);

      //   console.log(
      //     this.selectedYaxis,
      //     "min: ",
      //     this[this.selectedYaxis].min,
      //     "max: ",
      //     this[this.selectedYaxis].max,
      //     "yScale:",
      //     this[this.selectedYaxis].yScale
      //   );

      Plotly.newPlot("ScatterPlot", this.data, this.layout);
    }
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
  }
};
</script>
