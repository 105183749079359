<template>
  <div class="MoleculeCarousel">
    <div>
      <h2>Molecular properties of top 10 predicted pIC<sub>50</sub> values</h2>
      <v-carousel
        id="moleculeCarousel"
        show-arrows-on-hover
        hide-delimiters
        height="100%"
      >
        <v-carousel-item v-for="(item, i) in results" :key="i">
          <div class="img"><div v-html="item.picture"></div></div>
          <div class="item">
            <label for="">Rank: </label>
            <div>{{ item.ranked }}</div>
          </div>
          <div class="item">
            <label for="">ID: </label>
            <div>{{ item.origin_id }}</div>
          </div>
          <div class="item">
            <label for="">Isomeric SMILES: </label>
            <div>{{ item.smile }}</div>
          </div>
          <div class="item">
            <label for="">Chemical Formula: </label>
            <div>
              {{ item.others.chemical_formula }}
            </div>
          </div>
          <div class="item">
            <label for="">Molecular weight: </label>
            <div>{{ item.others.mw }}</div>
          </div>
          <div class="item">
            <label for="">LogP: </label>
            <div>{{ item.others.logp }}</div>
          </div>
          <div class="item">
            <label for="">HBA: </label>
            <div>{{ item.others.hba }}</div>
          </div>
          <div class="item">
            <label for="">HBD: </label>
            <div>{{ item.others.hbd }}</div>
          </div>
          <div class="item">
            <label for="">Rotatable Bonds: </label>
            <div>{{ item.others.rotatable_bond }}</div>
          </div>
          <div class="item">
            <label for="">Reliable: </label>
            <div>{{ item.others.reliable }}</div>
          </div>
        </v-carousel-item>
      </v-carousel>
      <div>
        <button class="btn btn-primary" @click="saveToCSV">
          <font-awesome-icon :icon="['fa', 'file-csv']" /> Download CSV file
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  props: {
    datum: {
      type: Array,
      required: true
    }
  },
  data() {
    return { results: [], csvContent: [] };
  },
  mounted() {
    let temp = [];
    let temp_csv = [];

    this.datum.forEach(key => {
      if (key["ranked"] <= 10) {
        temp[key["ranked"] - 1] = key;
        // console.log(key);
      }
    });

    this.results = temp;
    // console.log(this.results);

    temp.forEach(key => {
      temp_csv.push([
        key["ranked"],
        key["origin_id"],
        key["smile"],
        key["others"].chemical_formula,
        key["others"].mw,
        key["others"].logp,
        key["others"].hba,
        key["others"].hbd,
        key["others"].rotatable_bond,
        key["others"].reliable
      ]);
    });

    this.csvContent = temp_csv;
  },
  methods: {
    saveToCSV() {
      let filename = "top10_results.csv";
      let columns = [
        "Rank",
        "ID",
        "Isomeric SMILES",
        "Chemical Formula",
        "Molecular weight",
        "LogP",
        "HBA",
        "HBD",
        "Rotatable Bonds",
        "Reliable"
      ];

      let csv = Papa.unparse({ data: this.csvContent, fields: columns });
      if (csv == null) return;

      let blob = new Blob([csv]);
      if (window.navigator.msSaveOrOpenBlob)
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, filename);
      else {
        let a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = filename;
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
      }
    }
  }
};
</script>

<style scoped>
.MoleculeCarousel {
  border-radius: 20px;
  text-align: center;
  padding: 20px;
  padding-bottom: 30px;
  background-color: #e6e6f5;
}
.MoleculeCarousel h2 {
  font-family: "IBMPlexSans SemiBold";
  font-size: 1.4em;
}
.MoleculeCarousel > div {
  padding: 20px;
}
.MoleculeCarousel div {
  color: #000000;
}
.MoleculeCarousel .item {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-column-gap: 5%;
  margin: 15px 40px;
}
.MoleculeCarousel .item label {
  font-family: "IBMPlexSans SemiBold";
  color: #582e91;
  text-align: right;
}
.MoleculeCarousel .item div {
  text-align: left;
  display: inline-block;
  word-break: break-word;
}

@media screen and (max-width: 575px) {
  .MoleculeCarousel {
    padding: 20px 0px;
  }
  .MoleculeCarousel .item {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 20px;
  }
  .MoleculeCarousel .item label {
    text-align: left;
  }
}
</style>

<style>
#moleculeCarousel .v-window__prev {
  background: #582e91 !important;
  left: 0;
  opacity: 0.7;
}
#moleculeCarousel .v-window__prev i::before {
  font-size: 0.6em;
}
#moleculeCarousel .v-window__next {
  background: #582e91 !important;
  right: 0;
  opacity: 0.7;
}
#moleculeCarousel .v-window__next i::before {
  font-size: 0.6em;
}
.MoleculeCarousel .img img {
  border-radius: 15px;
  max-width: 300px;
  max-height: 300px;
  min-width: 250px;
  min-height: 250px;
  margin: 0 auto;
  -webkit-box-shadow: 4px 4px 41px -14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 4px 41px -14px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 41px -14px rgba(0, 0, 0, 0.2);
}
</style>
