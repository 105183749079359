<template>
  <div class="container">
    <div><v-img src="../assets/images/egfrnet_color.svg" class="img" /></div>
    <div>
      <v-img src="@/assets/images/molecule_line.webp" class="img" />
    </div>
    <div class="container-text text-center">
      <h3>Your job is processing for predicting bioactivity</h3>
      <div class="text-bold">
        The prediction may be taken few minutes or longer...
      </div>
      <div class="bubble">
        <v-img
          contain
          src="../assets/ellipsis_animation.svg"
          transition="scale-transition"
          width="150px"
          style="margin-top: -50px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "processing",
  methods: {},
};
</script>

<style scoped>
.container {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container .img {
  width: 250px;
  margin: 20px;
}
.container-text {
  margin-top: 30px;
}
h3 {
  margin: 0;
  color: #582e91;
}
div {
  color: #6c6c6c;
}
.bubble {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

@media screen and (max-width: 575px) {
  .container .img {
    width: 200px;
  }
}
</style>
