<template>
  <div>
    <header>
      <div>
        <h1>Predict bioactivity, pIC<sub>50</sub></h1>
      </div>
    </header>
    <div class="container-md results">
      <div>
        <h2 class="header-underline">pIC<sub>50</sub> prediction(s)</h2>
        <ResultsTable :datum="results.data" class="my-40" />
        <p>
          *If query compound shows
          <span class="colored">pIC<sub>50</sub> >= 6</span>, resulting in
          <span class="colored">active compound</span>; otherwise,
          <span class="colored">pIC<sub>50</sub> &lt; 6</span>, resulting in
          <span class="colored">inactive compound</span>.
        </p>
      </div>

      <div>
        <h2 class="header-underline">
          Top 10 predicted pIC<sub>50</sub> values
        </h2>
        <div class="my-20">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="#582E91" dark style="font-weight: bolder;"
                ><span class="mr-2">Download Visualization</span>
                <v-spacer></v-spacer>
                <v-icon>fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="downloadSpiderChart" link>
                <v-list-item-title>Spider Chart</v-list-item-title>
              </v-list-item>
              <v-list-item @click="downloadBarChart" link>
                <v-list-item-title>Bar Chart</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="article-cols">
          <div>
            <div class="container-chart">
              <!-- Spider/Radar Chart -->
              <radar-chart :datum="results.data" class="chart"></radar-chart>
            </div>
            <div class="container-chart">
              <!-- Bar Chart -->
              <bar-chart :datum="results.data" class="chart"></bar-chart>
            </div>
          </div>
          <div>
            <MoleculeCarousel
              :datum="results.data"
              class="moleculeCarousel-container"
            />
          </div>
        </div>
      </div>

      <div>
        <h2 class="header-underline">
          Scatter Plot: Predicted molecular properties of query compound(s)
        </h2>
        <ScatterPlot :datum="results.data" id="temp_ScatterPlot"></ScatterPlot>
      </div>
    </div>

    <v-row>
      <radar-chart
        id="temp_radarChart"
        :datum="results.data"
        style="width: 750px; height: 750px; position: absolute;"
      ></radar-chart>
    </v-row>
    <v-row>
      <bar-chart
        id="temp_barChart"
        :datum="results.data"
        style="width: 650px; height: 650px; position: absolute;"
      ></bar-chart>
    </v-row>
  </div>
</template>

<script>
import ResultsTable from "@/components/ResultsTable.vue";
import RadarChart from "@/components/RadarChart.vue";
import BarChart from "@/components/BarChart.vue";
import MoleculeCarousel from "@/components/MoleculeCarousel.vue";
// import D3ScatterPlot from "@/components/D3ScatterPlot.vue";
import ScatterPlot from "@/components/ScatterPlot.vue";

export default {
  name: "results",
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  components: {
    ResultsTable,
    RadarChart,
    BarChart,
    MoleculeCarousel,
    // D3ScatterPlot,
    ScatterPlot
  },
  data() {
    return {};
  },
  mounted() {
    // console.log(this.results);
    // hide temporary radar chart after render it once
    document
      .getElementById("temp_radarChart")
      .setAttribute("style", "display: none;");

    // hide temporary bar chart after render it once
    document
      .getElementById("temp_barChart")
      .setAttribute("style", "display: none;");
  },
  methods: {
    downloadSpiderChart() {
      // let radarChart = document.getElementById("radar-chart");
      // let radarChartCtx = radarChart.getContext("2d");
      // // radarChartCtx.scale(3, 3);

      // let temp_canvas = document.createElement("canvas");
      // // temp_canvas.setAttribute("id", "temp_canvas_spider");
      // let temp_context = temp_canvas.getContext("2d");
      // let height = 500;
      // let width = 500;
      // temp_context.canvas.width = width;
      // temp_context.canvas.height = height;
      // temp_context.canvas.style.width = width;
      // temp_context.canvas.style.height = height;
      // temp_context.scale(1, 1);
      // temp_context.drawImage(radarChart, 0, 0, width, height);
      // temp_context.drawImage(radarChart, 0, 0);

      // radarChartCtx.canvas.width = 900;
      // radarChartCtx.canvas.height = 900;
      // radarChartCtx.strokeStyle = "#bbbbbb";
      // radarChartCtx.fillStyle = "rgba(0, 0, 0, 1)";

      // console.log(radarChartCtx);

      let filename = "SpiderChart.png";

      let temp_radarChart = document.getElementsByClassName(
        "chartjs-render-monitor"
      )[2];
      // let temp_radarChart = document.getElementById("radar-chart");

      if (window.navigator.msSaveOrOpenBlob) {
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        let blob = temp_radarChart.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let a = document.createElement("a");
        // a.href = radarChart.toDataURL("image/png");
        a.href = temp_radarChart.toDataURL("image/png");
        a.download = filename;
        a.click();
      }
    },
    downloadBarChart() {
      // let barChart = document
      //   .getElementById("bar-chart")
      //   .toDataURL("image/png");

      let filename = "BarChart.png";

      let temp_barChart = document.getElementsByClassName(
        "chartjs-render-monitor"
      )[3];

      if (window.navigator.msSaveOrOpenBlob) {
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        let blob = temp_barChart.msToBlob();
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let a = document.createElement("a");
        a.href = temp_barChart.toDataURL("image/png");
        a.download = filename;
        a.click();
      }
    }
  }
};
</script>

<style scoped>
header::before {
  background-image: url("../assets/images/3d-illustration-molecule-model.webp");
}
.results h2 {
  color: #582e91;
}
.results > div {
  margin: 40px auto;
}
.colored {
  color: #582e91;
}
.article-cols > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chart {
  width: 100%;
}
.container-chart {
  margin: 30px 0px;
}

@media screen and (max-width: 575px) {
  .chart {
    width: 80vw;
  }
  .moleculeCarousel-container {
    width: 80vw;
  }
}
</style>

<style>
.v-input__slot i {
  font-size: 0.8em !important;
}
</style>
