<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="fa-search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        :class="{
          'mt-4': $vuetify.breakpoint.smAndDown
        }"
        @click="saveTableToCSV"
        color="#582E91"
        dark
        style="font-weight: bolder;"
        >Download Results
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="10"
      :sort-by="['ranked']"
      :sort-desc="[false, true]"
      :search="search"
      class="elevation-1"
      :loading="isLoading"
      loading-text="Loading data..."
      style="font-weight: bold"
      :mobile-breakpoint="0"
    >
    </v-data-table>
  </v-card>
</template>

<script>
// import axios from "axios";
// import ExcelJS from "exceljs";
// import FileSaver from "file-saver";

import Papa from "papaparse";

export default {
  data() {
    return {
      search: "",
      data: [],
      isLoading: true,
      headers: [
        {
          text: "No",
          align: "left",
          sortable: true,
          filterable: false,
          value: "index"
        },
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "origin_id"
        },
        {
          text: "Isomeric SMILES",
          sortable: true,
          value: "smile"
        },
        {
          text: "Predicted pIC50",
          value: "predicted"
        },
        {
          text: "IC50 (nM)",
          value: "predicted_nm"
        },
        {
          text: "Ranking",
          filterable: false,
          value: "ranked"
        },
        {
          text: "Reliable",
          value: "others.reliable"
        },
        {
          text: "Active/Inactive compound*",
          align: "center",
          value: "others.compound"
        }
      ]
    };
  },
  created() {
    this.isLoading = true;
    this.getResults();
    // console.log(this.datum);
  },
  props: {
    datum: {
      type: Array,
      required: true
    }
  },
  methods: {
    getResults() {
      this.data = this.datum.map((items, index) => ({
        ...items,
        index: index + 1
      }));

      this.isLoading = false;
    },
    saveTableToCSV() {
      let csvContent = [];

      let filename = "EGFRNet_results.csv";
      let columns = [
        "No",
        "ID",
        "Isomeric SMILES",
        "Predicted pIC50",
        "IC50 (nM)",
        "Ranking",
        "Reliable",
        "Active/Inactive compound*"
      ];

      this.data.forEach(key => {
        csvContent.push([
          key["index"],
          key["origin_id"],
          key["smile"],
          key["predicted"],
          key["predicted_nm"],
          key["ranked"],
          key["others"].reliable,
          key["others"].compound
        ]);
      });

      csvContent.push([
        "",
        "",
        "*If query compound shows pIC50 >= 6, resulting in active compound; otherwise, pIC50 < 6, resulting in inactive compound."
      ]);

      let csv = Papa.unparse({ data: csvContent, fields: columns });
      if (csv == null) return;

      let blob = new Blob([csv]);
      if (window.navigator.msSaveOrOpenBlob)
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, filename);
      else {
        let a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = filename;
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
      }
    }

    // saveTableToExcel() {
    //   // Create a workbook & add worksheet
    //   let workbook = new ExcelJS.Workbook();
    //   let worksheet = workbook.addWorksheet("EGFRNet_results", {
    //     properties: {
    //       tabColor: { argb: "FF00FF00" },
    //       outlineLevelCol: 1,
    //       defaultRowHeight: 15
    //     },
    //     pageSetup: {
    //       paperSize: 9,
    //       fitToPage: true,
    //       fitToHeight: 5,
    //       fitToWidth: 5
    //     }
    //   });

    //   // Add column headers
    //   worksheet.columns = [
    //     { header: "No", key: "index" },
    //     { header: "ID", key: "origin_id" },
    //     { header: "Isomeric SMILES", key: "smile" },
    //     { header: "Predicted pIC50", key: "predicted" },
    //     { header: "IC50 (nm)", key: "predicted_nm" },
    //     {
    //       header: "Ranking",
    //       key: "ranked"
    //     }
    //   ];

    //   // worksheet.columns.forEach(column => {
    //   //   column.width = column.header.length < 12 ? 12 : 20;
    //   //   // column.width = column.header.length;
    //   // });

    //   // Add row using key mapping to columns
    //   // worksheet.addRow(
    //   //   { package_name: "ABC", author_name: "Author 1" },
    //   //   { package_name: "XYZ", author_name: "Author 2" }
    //   // );

    //   // Add rows as Array values
    //   // worksheet.addRow(["BCD", "Author Name 3"]);

    //   // Add rows using both the above of rows
    //   // let rows = [
    //   //   [
    //   //     "team A",
    //   //     2,
    //   //     4.5,
    //   //     3.5,
    //   //     3.5,
    //   //     3.5,
    //   //     1.5,
    //   //     4.5,
    //   //     3,
    //   //     4,
    //   //     4,
    //   //     4.5,
    //   //     3,
    //   //     4.5,
    //   //     3.5,
    //   //     4.5,
    //   //     1.5,
    //   //     2,
    //   //     4,
    //   //     2
    //   //   ]
    //   //   // { package_name: "PQR", author_name: "Author 5" }
    //   // ];

    //   let rows = this.data.map(row => {
    //     return row;
    //   });

    //   // console.log(rows);
    //   worksheet.addRows(rows);

    //   // Save workbook to local storage
    //   let filename = "EGFRNet_results.xlsx";

    //   workbook.xlsx
    //     .writeBuffer()
    //     .then(data => {
    //       FileSaver.saveAs(new Blob([data]), filename);
    //       // console.log("Excel file downloaded!");
    //     })
    //     .catch(() => {
    //       // console.log("err", err);
    //     });
    // },
  }
};
</script>
