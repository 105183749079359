<script>
//Importing Bar class from the vue-chartjs wrapper
import { Bar } from "vue-chartjs";
//Exporting this so it can be used in other components
export default {
  extends: Bar,
  props: {
    datum: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      datacollection: {
        //Data to be represented on x-axis
        labels: [
          "FIrst",
          "Second",
          "Third",
          "Fourth",
          "Fifth",
          "Sixth",
          "Seventh",
          "Eighth",
          "Nineth",
          "Tenth"
        ],
        datasets: [
          {
            label: "Predicted pIC50",
            order: 1,
            backgroundColor: [
              "rgba(76, 170, 232, 0.7)",
              "rgba(108, 97, 176, 0.7)",
              "rgba(118, 158, 206, 0.7)",
              "rgba(138, 92, 155, 0.7)",
              "rgba(87, 120, 158, 0.7)",
              "rgba(95, 77, 120, 0.7)",
              "rgba(98, 180, 235), 0.7",
              "rgba(124, 114, 187, 0.7)",
              "rgba(134, 170, 212, 0.7)",
              "rgba(153, 109, 168, 0.7)"
            ],
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            //Data to be represented on y-axis
            data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70]
          }
        ]
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: "Predicted pIC50"
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                fontStyle: "bolder",
                fontSize: 14
              },
              scaleLabel: {
                display: true,
                labelString: "ID of query compound(s)"
              }
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    let temp_label = [];
    let temp_data = [];

    this.datum.forEach(key => {
      if (key["ranked"] <= 10) {
        // temp_label.push(key["origin_id"]);
        // temp_data.push(key["predicted"]);
        temp_label[key["ranked"] - 1] = key["origin_id"];
        temp_data[key["ranked"] - 1] = key["predicted"];
      }
    });

    this.datacollection.labels = temp_label;
    this.datacollection.datasets[0].data = temp_data;

    //renderChart function renders the chart with the datacollection and options object.
    this.renderChart(this.datacollection, this.options);
  }
};
</script>
