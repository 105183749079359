<script>
//Importing Bar class from the vue-chartjs wrapper
import { Radar } from "vue-chartjs";
//Exporting this so it can be used in other components
export default {
  extends: Radar,
  props: {
    datum: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      datacollection: {
        labels: [
          "DopaD2",
          "alpha1b",
          "5HT2A",
          "SeroTrans",
          "alpha2a",
          "alpha1d"
        ],
        datasets: [
          {
            label: "Predicted pIC50",
            backgroundColor: "rgba(88, 46, 145, 0.2)",
            borderColor: "#582E91",
            borderWidth: 2,
            pointBorderColor: "White",
            data: [1, 1, 0.9, 0.5, 0.4, 0.5]
          }
        ]
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        scale: {
          pointLabels: {
            fontStyle: "bolder",
            fontSize: 14
          },
          angleLines: {
            display: true
          },
          ticks: {
            fontSize: 14,
            suggestedMin: 1,
            suggestedMax: 10,
            stepSize: 1
          }
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: function(tooltipItem, data) {
              let datasetLabelIndex = tooltipItem[0].index;
              // console.log("tooltipItem:", tooltipItem);
              // console.log("data:", data);
              // console.log("datasetLabelIndex:", datasetLabelIndex);
              // console.log("data.datasets[0]:", data.labels[datasetLabelIndex]);
              return data.labels[datasetLabelIndex];
            }
          }
        }
      }
    };
  },
  mounted() {
    let temp_label = [];
    let temp_data = [];

    this.datum.forEach(key => {
      if (key["ranked"] <= 10) {
        temp_label.push(key["origin_id"]);
        temp_data.push(key["predicted"]);
      }
    });

    let suggestedMin = Math.min(...temp_data);
    let suggestedMax = Math.max(...temp_data);

    this.datacollection.labels = temp_label;
    this.datacollection.datasets[0].data = temp_data;

    if (suggestedMin >= 1) {
      this.options.scale.ticks.suggestedMin = Math.round(suggestedMin) - 1;
    } else {
      this.options.scale.ticks.suggestedMin = 0;
    }
    this.options.scale.ticks.suggestedMax = suggestedMax + 1;

    this.options.scale.ticks.stepSize = Math.round(
      (suggestedMax - suggestedMin) / 5
    );

    //renderChart function renders the chart with the datacollection and options object.
    this.renderChart(this.datacollection, this.options);
  }
};
</script>
